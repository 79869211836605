import ProductListingBlockModel from '../../Models/Blocks/ProductListingBlock/ProductListingBlockModel.interface';
import ScrollContainer from 'react-indiana-drag-scroll';
import { styled } from '../../stitches.config';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import useMedia from '../../Shared/Hooks/useMedia';
import ProductCard from '../ProductCard/ProductCard';
import { H2 } from '../../Atoms/Typography/Headings/Heading';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import IconLink from '../../Atoms/Links/IconLink';
import { useMemo } from 'react';
import GridItem from 'Atoms/Grids/GridItem';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';

type PropTypes = {
  content: ProductListingBlockModel;
  noPadding?: boolean;
};

function ProductListingBlock({
  content: { header, productCards, inEditMode, link: links },
  noPadding = true,
}: PropTypes) {
  const isContentWidth = useMedia(mediaQueryTypes.mediaMinGreat);
  const isHorizontalScroll = productCards.length > 2 || !isContentWidth;
  const link = links[0];
  const visibleProducts = useMemo(
    () => productCards.slice(0, 4),
    [productCards]
  );

  return (
    <GridItem layout="1/1">
      <ContentContainer noPadding={noPadding} css={{ mb: 16 }}>
        <H2 css={Heading}>{header}</H2>
        <ProductWrapper horizontal={isHorizontalScroll}>
          {visibleProducts.map((product, i) => {
            return (
              <ProductCardWrapper
                key={product.code}
                css={{
                  pl: product.promotion?.promotionName && i === 0 ? 2 : 0,
                }}
              >
                <ProductCard item={product} />
              </ProductCardWrapper>
            );
          })}
        </ProductWrapper>
        {link?.text && link.href && (
          <LinkContainer>
            <IconLink
              {...applyEditModeAttr(inEditMode ? 'Link' : false)}
              whiteText={true}
              href={link.href}
            >
              {link.text}
            </IconLink>
          </LinkContainer>
        )}
      </ContentContainer>
    </GridItem>
  );
}

const ProductCardWrapper = styled('div', {
  width: '296px',
  flexShrink: 0,
});

const ProductWrapper = styled(ScrollContainer, {
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'flex-start',
  columnGap: '24px',
  flexWrap: 'nowrap',
  '@mediaMinHuge': {
    columnGap: '32px',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const Heading = {
  textAlign: 'left',
  fs: '32px',
  mb: 8,
  mt: 0,
  fontFamily: '$fontPrimary400',
};

const LinkContainer = styled('div', {
  w: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  mt: 8,
});

export default ProductListingBlock;
